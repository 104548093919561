// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/views/MainView.vue';
import PublicView from '@/views/PublicView.vue';
import AboutView from '@/views/AboutView.vue';
import ProjectView from '@/views/ProjectView.vue';
import ArtistProfileView from '@/views/ArtistProfileView.vue';
import ArtistDetailView from '@/views/ArtistDetailView.vue';

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
    meta: { title: 'Sizak 시작' },
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
    meta: { title: 'About' },
  },
  {
    path: '/publication',
    name: 'PublicView',
    component: PublicView,
    meta: { title: 'Publication' },
  },
  {
    path: '/project',
    name: 'ProjectView',
    component: ProjectView,
    meta: { title: 'Project' },
  },
  {
    path: '/artists/:artistId',
    name: 'ArtistProfileView',
    component: ArtistProfileView,
    props: true,
    children: [
      {
        path: ':workId',
        name: 'ArtistDetailView',
        component: ArtistDetailView,
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 페이지 이동 후 제목 설정
router.afterEach((to) => {
  document.title = to.meta?.title ?? '시작 SIZAK';
});

export default router;