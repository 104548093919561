<template>
  <div class="main-view">
    <div class="main-view__container-img">
      <div 
        class="main-view__img-wrap" 
        v-for="artist in artists" 
        :key="artist.router"
      >
        <div
          class="main-view__thumbnail-image-container"
          @click="toggleSelection(artist.router)"
          :class="{ 'is-hovered': isSelected(artist.router) }"
        >
          <router-link class="main-view__link" :to="{ name: 'ArtistProfileView', params: { artistId: artist.router } }">
            <img
              :src="artist.lowResThumbnail"
              :data-src="artist.thumbnail"
              :alt="artist.names"
              class="main-view__thumbnail-image"
              @load="upgradeToHighResImage"
              @error="handleImageError"
            />
          </router-link>
          <div class="main-view__artist-name">{{ artist.names }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainView',
  data() {
    return {
      artists: [
        { names: 'Jung Youngdon 정영돈', router: 'YoungdonJung', lowResThumbnail: require('@/assets/work/JYD/1/low1.jpg'), thumbnail: require('@/assets/work/JYD/1/1.jpg') },
        { names: 'Kim Chankyu 김찬규', router: 'ChankyuKim', lowResThumbnail: require('@/assets/work/KCK/1/lowthum.jpg'), thumbnail: require('@/assets/work/KCK/1/thum.jpg') },
        { names: 'Kim Hyungsik 김형식', router: 'HyungsikKim', lowResThumbnail: require('@/assets/work/KHS/1/low1.jpg'), thumbnail: require('@/assets/work/KHS/1/1.jpg') },
        { names: 'Kim Jaeyeon 김재연', router: 'JaeyeonKim', lowResThumbnail: require('@/assets/work/KJY/3/low1.jpg'), thumbnail: require('@/assets/work/KJY/3/1.jpg') },
        { names: 'Ahn Jungjin 안정진', router: 'JungjinAhn', lowResThumbnail: require('@/assets/work/AJJ/2/low1.jpg'), thumbnail: require('@/assets/work/AJJ/2/4.jpg') },
        { names: 'Jung Jihyun 정지현', router: 'JihyunJung', lowResThumbnail: require('@/assets/work/JJH/1/low1.jpg'), thumbnail: require('@/assets/work/JJH/1/1.jpg') },
        { names: 'Kim Taejoon 김태중', router: 'TaejoongKim', lowResThumbnail: require('@/assets/work/KTJ/2/low1.jpg'), thumbnail: require('@/assets/work/KTJ/2/1.jpg') },
        { names: 'Yun Taejun 윤태준', router: 'TaejunYun', lowResThumbnail: require('@/assets/work/YTJ/1/low1.jpg'), thumbnail: require('@/assets/work/YTJ/1/1.jpg') },
        { names: 'Yoo Youngjin 유영진', router: 'YoungjinYoo', lowResThumbnail: require('@/assets/work/YYJ/3/low1.jpg'), thumbnail: require('@/assets/work/YYJ/3/1.jpg') },
        { names: 'Choi Eunjoo 최은주', router: 'EunjuChoi', lowResThumbnail: require('@/assets/work/CEJ/1/low1.jpg'), thumbnail: require('@/assets/work/CEJ/1/1.jpg') },
        { names: 'Jung Chanmin 정찬민', router: 'ChanminJung', lowResThumbnail: require('@/assets/work/JCM/1/low1.jpg'), thumbnail: require('@/assets/work/JCM/1/1.jpg') },
        { names: 'Jung Youngho 정영호', router: 'YounghoJung', lowResThumbnail: require('@/assets/work/JYH/4/low1.jpg'), thumbnail: require('@/assets/work/JYH/4/1.jpg') },
        { names: 'Jung Seungwon 정승원', router: 'SeungwonJung', lowResThumbnail: require('@/assets/work/JSW/2/low1.jpg'), thumbnail: require('@/assets/work/JSW/2/1.jpg') },
        { names: 'Cristian Doeller 크리스티안 도엘러', router: 'CristianDoeller', lowResThumbnail: require('@/assets/work/CRD/1/low1.jpg'), thumbnail: require('@/assets/work/CRD/1/1.jpg') },
        { names: 'Lee Hyeonwoo 이현우', router: 'HyeonwooLee', lowResThumbnail: require('@/assets/work/LHW/2/low1.jpg'), thumbnail: require('@/assets/work/LHW/2/1.jpg') },
        { names: 'Lee Yeeun 이예은', router: 'YeeunLee', lowResThumbnail: require('@/assets/work/LYE/2/low1.jpg'), thumbnail: require('@/assets/work/LYE/2/1.jpg') },
        { names: 'Kim Doyoung 김도영', router: 'DoyoungKim', lowResThumbnail: require('@/assets/work/KDY/3/low1.jpg'), thumbnail: require('@/assets/work/KDY/3/1.jpg') },
        { names: 'Zoe Popp 조이 폽', router: 'ZoePopp', lowResThumbnail: require('@/assets/work/ZOE/1/1.jpg'), thumbnail: require('@/assets/work/ZOE/1/1.jpg') },
        { names: 'Jang Soyoung 장소영', router: 'SoyoungJang', lowResThumbnail: require('@/assets/work/JSY/1/1.jpg'), thumbnail: require('@/assets/work/JSY/1/1.jpg') },
        { names: 'Yoo Hyejeong 유혜정', router: 'HyejeongYoo', lowResThumbnail: require('@/assets/work/YHJ/1/1.jpg'), thumbnail: require('@/assets/work/YHJ/1/1.jpg') }
      ],
      selectedIndex: null
    }
  },
  created() {
    this.shuffleArray(this.artists);
  },
  methods: {
    shuffleArray(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
    },
    toggleSelection(routerId) {
      this.selectedIndex = this.selectedIndex === routerId ? null : routerId;
    },
    isSelected(routerId) {
      return this.selectedIndex === routerId;
    },
    upgradeToHighResImage(event) {
      const img = event.target;
      const highResSrc = img.getAttribute('data-src');
      if (highResSrc) {
        const tempImg = new Image();
        tempImg.src = highResSrc;
        tempImg.onload = () => {
          img.classList.add('loaded');
          img.src = highResSrc;
        };
      }
    },
    handleImageError(event) {
      const img = event.target;
      // 저해상도 이미지 로드 실패 시 고해상도 이미지로 대체 시도
      img.src = img.getAttribute('data-src');
    }
  }
}
</script>

<style scoped>
.main-view {
  overflow-x: hidden;
}

.main-view__container-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: auto;
}

.main-view__thumbnail-image-container {
  width: 100%;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.main-view__thumbnail-image-container img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.main-view__thumbnail-image-container img.loaded {
  opacity: 1;
}

.main-view__artist-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 1em 3.8em;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease;
  text-align: center;
  border-radius: 10px;
}

.main-view__thumbnail-image-container.is-hovered .main-view__artist-name {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) and (min-width: 426px) {
  .main-view__container-img {
    width: 100%;
    justify-content: space-between;
    overflow: auto;
  }
  .main-view__thumbnail-image-container img {
    height: auto;
    max-width: 100px;
  }
}

@media (min-width: 320px) and (max-width: 430px) {
  .main-view__container-img {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
  }

  .main-view__thumbnail-image-container {
    margin: 0;
    padding: 0;
  }

  .main-view__thumbnail-image-container img {
    width: calc(100vw / 3.3 - 3px);
    height: calc(100vw / 3.3 - 3px);
    max-width: 135px;
    min-width: 80px;
    max-height: 135px;
    min-height: 80px;
  }
}
</style>