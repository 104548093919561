// artistCVs.js
export const artistCVs = {
// 윤태준
'TaejunYun': {
    content: `
<p>1987년 서울출생, 서울에서 거주 및 활동
Born in 1987 Seoul, Korea. Lives and works in Seoul

<strong>Education</strong>
2020 중앙대학교 일반대학원 순수사진전공 졸업 
2015 중앙대학교 공연영상창작학부 사진전공 졸업
2013 빌레펠트 응용대학교, 교환학생 프로그램, 사진과 미디어 
2020 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2007 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea
2013 Exchange Programe, Photography and Media, University of Applied Sciences, Bielefeld, Germany

<strong>Solo Exhibitions </strong>
2021 Middleturn, Space xShift, Seoul, Korea
2018 Will We Live on Stone in the Future, Ilhyun Museum of Art, Eulji Space, Seoul, Korea
Be Silent About the Unspeakable, Encounter 2018- Daily Rations, Public Art Project, Mumbai, India
2016 The Weight of Regret, Alternative Space Noon, Suwon, Korea
Suwon Notice, Cabinet Seoul Sewunsanga, Seoul, Korea 

<strong>Group Exhibitions</strong>
2023 Photography, Familiar and Unfamiliar Words, Ami Art Museum, Dangjin, Korea
2023 Spaceless + 60 swiss photobook, 14th Gwangju biennale-Swiss Pavilion, Gwangju, Korea
2022 Spaceless, Swiss Embassy in Korea, Seoul, Korea
2022 Where Are We Now?, Sungkok Art, Museum, Seoul, Korea
2022 The boundary of Photography, Gwangju Museum of art Photography Exhibition hall, Gwangju, Korea
2021 Junge fotografische Positionen aus Deutschland und Südkorea, Goethe-institute, Seoul, Korea
2021 End, after arrived, Sansu-ssari, Gwangju, Korea
2021 VISIBLE VOICES, Centre for contemporary art laznia, Gdansk, Poland
2021 Calling, D slash P, Seoul, Korea
2020 C2A Question, Can Foundation, Seoul, Korea
2020 Clickscrollzoom, clickscrollzoom.com, Web
2019 Gap Time, Ilhyeon Museum of Art, Yangyang, Korea
2019 Homegination, Space 22, Seoul, Korea
2018 Seoul, Mumbai to New Delhi, Korean Cultural Center Judo, New Delhi, India
2017 Illusion Stairs, Community Art 2017, Hello, Seoul Museum of Art, Seoul, Korea
2016 Flower Fire, Space Woman_Jirisan Silsangsa Art Project 2016, Jirisan Silsangsa Temple, Namwon, Korea
2016 Slices On Time, Villa Romana House, Florence, Italy
2016 New Generation – SIZAK, Center for Contemporary Art Museum of Laznia, Gdansk, Poland
2016 Different Dimension, Photography Festival, Novosibirsk Museum, Novosibirsk, Russia
2016 Open-end(ed), Seohak-dong Photo Studio, Jeonju, Korea
2016 Reinterpretation of Memories, Freud Museum, St. Petersburg, Russia
2015 Ilhyeon Grant Travel 2015, Ilhyeon Museum of Art, Yangyang, Korea
2015 Photo Island Festival 2015 Now, The South Studios, Dublin, Ireland
2015 Blind Man, Like Touching an Elephant, Here and Now, Seoul, Korea
2013 The Texas Project, The Texas Project, Seoul, Korea

<strong>Projects </strong>
2020 Asian Culture Research Group, 2020 Asian Culture Forum, ACC, Gwangju, Korea
2020 Creator Workshop AtoZ, Can Foundation, Seoul, Korea
2020 Cureto-Realism ver.2, Exhibition Planning <Piercing Through the Gap>, Gwangju Cultural Foundation, Gwangju, Korea
2020 GQ Magazine, Item feature section, Issue 26, Doosan Magazine
2020 The-Scrap, Happy Together, 24, Wangsan-ro 9-gil, Dongdaemun-gu, Korea
2019 House called Hanok, Public Art Project, Cooperation National Trust-Remark Press, Seoul, Korea
2017 Will We Live on Stone in the Future?, Cabinet Seoul Sewunsanga, Seoul, Korea 2016~2018 Cabinet Seoul_Sewoon Sangga, Artist Learning Space, Seoul, Korea
2016 The-Scrap, 24 Wangsan-ro 9-gil, Dongdaemun-gu, Korea
2016 UE8 Seoul Art Book Fair, Ilmin Museum of Art, Korea

<strong>Awards</strong>
2015 Ilhyun Grant Travel 2015, Ilhyun Museum, Korea

<strong>Selection and collection</strong>
2020 Daily Exhibition through Support for New Artists, Seoul City Hall Cultural Headquarters Museum Division, Korea
2018 Ilhyeon Travel Grant 10th Anniversary Contest, Ilhyeon Museum of Art, Korea 2015 Ilhyeon Grant Travel 2015, Ilhyeon Museum of Art, Korea

<strong>Article </strong>
Photoart, Show Review, May, 2023
Photoart Magazine, article, Apr, 2022
Vostok Magazine, Interview, May, 2021
Monthly photography magazine, Interview, March, 2021
Photodot, Interview, Link Path, May, 2016
Kyunghyang new magazine, article, May, 2016
Image Fragmentation, Interview Lee, Nov, 2016
Art in Culture, Interview, New Look, Aug, 2015
`,
    website: 'https://www.taejunyun.com'
  },
  
  // 유영진
'YoungjinYoo':  {
    content: `
<p>1988년 김포출생, 서울에서 거주 및 활동 
Born in 1988 Gimpo, Korea. Lives and works in Seoul

<strong>Education </strong>
2013 중앙대학교 사진학과 졸업 
2013 B.F.A in Fine Art Photography Chung-Ang University, Korea  

<strong>Selected Group Exhibitions</strong>
2015 'SeMA Modern History Project Evolved Museum: the former Belgian Consulate', Nam-Seoul Living Arts Museum, Seoul, Korea 
           'Blind Date', Space Willing&dealing, Seoul, Korea 
           ‘Photo Ireland Festival - JIGEUM’, South Studio, Dublin, Ireland 
           'Silence of Flaubert', gallery Skape, Seoul, Korea 
2014  ‘New Generation - SIZAK’, Galleri Image, Aarhus, Denmark 
           ‘New Generation - SIZAK’, The Museum of Photography, Seoul, Korea 
2013  'Lianzhou international Photo Festival', Lianzhou, China 
           'Alt. +1000 Festival de Photographie', Rossiniere, Switzerland 
           'Peep!', Songwon Artcenter, Seoul, Korea 
2012  '5th European Month of Photography Berlin', IN TRANSIT, Berlin, Germany 
           'Breda Photo international Festival', Museum of the Image(MOTI), Breda, Netherland 
           'ARTFLASH', KIAF, Seoul, Korea

<strong>Selected Projects </strong>
2012 'The 3rd The Art Factory Project', SUNSET JANGHANG FESTIVAL, Janghang, Korea 
           'Public Art Project' <UNUS space>, Seoul, Korea  

<strong>Awards & Artist in Residency </strong>
2013 Artist in Residence, Centre for Contemporary Art Łaźnia, Gdansk, Poland
</p>
`,     website: 'cargocollective.com/photokomori'
},

// 정영돈
'YoungdonJung':  {
    content: `
<p>1988년 서울출생, 서울에서 거주 및 활동
Born in 1988 Seoul, Korea. Lives and works in Seou

<strong>Education </strong>
2016 중앙대학교 일반대학원 순수사진전공 재학
2014 중앙대학교 사진학과 졸업
2016 M.A. in course Fine Art Photography, Chung-Ang University, Korea
2014 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2016 ‘Wondering Wandering’, SongEun ArtCube, Seoul, Korea
2014 ‘Evocation; Ventilation’, Total Museum for Contemporary Art – Project Space The Room, Seoul, Korea

<strong>Group Exhibitions </strong>
2015  ‘Media Project, Archive Review’ (Moojin Brothers), Arko Art Center, Seoul, Korea
        ‘Mother, My First Love’ (Moojin Brothers), Jeju Museum of Art, Jeju, Korea
        ‘AR–Towns Art Festival’ (Moojin Brothers), A garbage incineration plant, NASN, Seoul, Korea
        ‘Seoul International New Media Festival 15th’ (Moojin Brothers), Seoul, Korea
        ‘Photo Ireland Festival - JIGEUM’, South Studio, Dublin, Ireland
        ‘Camera-Human Ⅱ’, IGONG Gallery, Daejeon, Korea
2014  'Photobook Week Aarhus’, Galleri Image, Aarhus, Denmark
‘Inner Color, Open Studio’, Centre for Contemporary Art Łaźnia, Gdansk, Poland
‘Cud nad martwą wisłą’, Centre for Contemporary Art Łaźnia, Gdansk, Poland
‘Parkowanie Art Festival’ (Moojin Brothers), A Kuku Sztuka, Gdansk, Poland
‘New Generation - SIZAK’, Galleri Image, Aarhus, Denmark 
‘New Generation - SIZAK’, The Museum of Photography, Seoul, Korea 
‘The 4th Art Factory Project’, Culture Station Seoul 284, Seoul, Korea 
‘High Potentia’, On Gallery, Seoul, Korea 
2013  ‘LianZhou International Photo Festival’, GuangDong, China 
‘The Night of Sponsor’, KJ Choi Foundation, Incheon, Korea 
‘Alt. +1000 Festival de Photographie’, Rossinière, Switzerland 
‘BYOB Film Festival’ (Moojin Brothers), Space O’New Wall, Seoul 
‘Alt. +1000 Festival de Photographie’, Rossiniere, Switzerland 
‘Ghost live in our house’ (Moojin Brothers), Space Ggul, Seoul, Korea 
2012  ‘Media Project : The Unbound Archive’ (Moojin Brothers), Arko Art Center, Seoul, Korea 

<strong>Projects, Screenings </strong>
2015  ‘Against Photography’, National Museum of Modern and Contemporary Art, Seoul, Korea 
2014  ‘The Field of M, Report Exhibition’ (Moojin Brothers), Non-Bat Gallery, Paju, Korea 
‘The Field of M, Art Project’ (Moojin Brothers), Kyunggi Culture Foundation, Paju, Korea 
‘A Cabinet of Exhibitions’ Screening, Arko Art Center, Seoul, Korea 
‘Inner Color Project’, Centre for Contemporary Art Łaźnia, Gdansk, Poland 
2013  ‘TAK Project, in Europe’ (Moojin Brothers), Rossinière-Paris-London-Praha-Wien Round Exhibition 
2013  ‘Byungboc LEE: ActⅢ, Chapter3’ (Moojin Brothers), Screening, Arko Art Center, Seoul, Korea 

<strong>Grants, Awards, Artist in residence </strong>
2016  SEMA-Emerging Artists (Moojin Brothers), Seoul Museum of Art, Seoul, Korea 
National Endowment Fund for Culture and the Arts - International Exchange, Arts Council Korea 
2015  Portfolio Review 1st Winner, Photo Ireland Festival, Dublin, Ireland 
Glocal Contest Grand Prize (Moojin Brothers), Seoul International New Media Festival 15th, Seoul, Korea 
Arko Art Center Media Archive Selected (Moojin Brothers), Arco Art Center, Seoul, Korea 
2014  National Endowment Fund for Culture and the Arts - International Exchange, Arts Council Korea 
Artist in Residence, Centre for Contemporary Art Łaźnia, Gdansk, Poland 
National Endowment Fund for Culture and the Arts (Moojin Brothers), Kyunggi Culture Foundation, Seoul, Korea 
2013  Artist in Residence (Moojin Brothers), Arko Art Creative Center, Seoul, Korea 
2012  Arko Art Center Media Archive Selected (Moojin Brothers), Arco Art Center, Seoul, Korea 

<strong>Publication</strong>
2015  ‘Inner color’, Centre for Contemporary Art Łaźnia, Gdansk, Poland 
</p>
`, website: 'moojinbrothers.com'
}, 

// 김찬규
'ChankyuKim': {
    content: `
<p>1988년 서울출생, 서울에서 거주 및 활동 
Born in 1988 Seoul, Korea. Lives and works in Seoul

<strong>Education </strong>
2016 중앙대학교 일반대학원 순수사진전공 수료 
2014 중앙대학교 사진학과 졸업 
2016 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2014 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Group Exhibitions </strong>
2015 ‘PhotoIreland Festival – JIGEUM’, South Studio, Dublin, Ireland
2014 'PhotoBook Week Aarhus’, Aarhus, Denmark
‘Chang won Sculpture Biennale - New Shinshin Sajinkwan’, Masan, Chang won, Korea
‘New Generation - SIZAK’, Galleri image, Aarhus, Denmark
‘New Generation - SIZAK’, The Museum of Photography, Seoul, Korea
‘Asian International College Exhibition’, Seoul, Korea
‘The 4th The Art Factory Project’, Culture Station Seoul 284, Seoul, Korea
2013 ‘Lianzhou International Photo Festival’, Lianzhou, China
‘Alt. +1000 Festival de Photographie’, Rossiniere, Switzerland
2012 ‘Mirae Award Exhibition’, Canon Plex Gallery, Seoul, Korea

<strong>Project </strong>
2015 ‘Against Photography’, National Museum of Modern and Contempory Art, Seoul, Korea
</p>
`, website: ' '
}, 
//김형식
'HyungsikKim' : {
    content: `
<p>1979년 서울출생, 서울에서 거주 및 활동
Born in 1979 Seoul, Korea. Lives and works in Seoul

<strong>Education</strong>
2016 중앙대학교 일반대학원 순수사진전공 졸업 
2007 중앙대학교 사진학과 졸업 
2016 M.F.A. in Fine Art Photography, Chung-Ang University, Korea 
2007 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea 

<strong>Solo Exhibitions </strong>
2016 'Based on a True Story', BMW Photospace, Goeun Museum of Photography, Busan, Korea 
‘Photograph, Studio and Deception’, Gallery Factory, Seoul, Korea 
2014 ‘Distortion’, Total Museum for Contemporary Art – Project Space The Room, Seoul, Korea

<strong>Group Exhibitions </strong>
2017 Community Art ‘Annyeonghaseyo’, SeMA, Buk-Seoul Museum of Art, Seoul
‘Unknown knowns’, Gallery SKAPE, Seoul, Korea
2015 ‘PhotoIreland Festival – JIGEUM’, South Studio, Dublin, Ireland
2014 'Photobook Week Aarhus’, Galleri Image, Aarhus, Denmark
‘Chang won Sculpture Biennale - New Shinshin Sajinkwan’, Chang won, Korea
‘New Generation - SIZAK’, Galleri image, Aarhus, Denmark
‘New Generation - SIZAK’, The Museum of Photography, Seoul, Korea
‘The 4th The Art Factory Project’, Culture Station Seoul 284, Seoul, Korea
2013 ‘TRIALOG Music/Video/Performance’, Goethe Institute / Total museum, Seoul, Korea
‘Alt. +1000 Festival de Photographie’, Rossiniere, Switzerland
2012 ‘Young Photographer Award’, Topohaus, Seoul, Korea

<strong>Project </strong>
2015 ‘Against Photography’, National Museum of Modern and Contempory Art, Seoul, Korea

<strong>Public Collection </strong>
Arkitektskolen Aarhus(Aarhus School of Architecture), Aarhus, Denmark
Goeun Museum of Photography, Busan, Korea
</p>
`, website: 'www.hyungsikkim.com'
}, 


// 김재연
'JaeyeonKim': {
    content: `
<p>1989년 대전출생, 서울에서 거주 및 활동
Born in 1989 Daejeon, Korea. Lives and works in Seoul

<strong>Education </strong>
2017 중앙대학교 일반대학원 순수사진전공 졸업 
2013 중앙대학교 사진학과 졸업 
2017 M.F.A. course in Fine Art Photography, Chung-Ang University, Korea
2013 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2017 ‘ARTLAB DAEJEON’, Leeungno Museum, Daejeon, Korea

<strong>Group Exhibitions </strong>
2017 ‘Oasis’, Daejeon Museum of Art, Daejeon, Korea
‘Community Art, Annyeonghaseyo’, Buk Seoul Museum of Art, Seoul, Korea
2016 ‘Jirisan Project 2016 - Porject SIZAK’, Silsangsa, Namwon
‘A Piece Of Cockscomb’, Cabinet Seoul, Seoul, Korea
‘New Generation - SIZAK’, Laznia Center for Contemporary Art, Gdansk, Poland
‘Slices - On Time’, Villa Romana, Firenze
2015 ‘PhotoIreland Festival – JIGEUM’, South Studio, Dublin, Ireland
‘As blind men touch an elephant’, NOWHERE, Seoul, Korea
2014 ‘7.01kg’, Art Space SAY, Seoul, Korea
</p>
`, website: ' '
}, 

// 안정진
'JungjinAhn': {
    content: `
<p>1985년 대전출생, 서울에서 거주 및 활동 
Born in 1985 Daejeon, Korea. Lives and works in Seoul

<strong>Education </strong>
2018 중앙대학교 일반대학원 순수사진전공 수료
2013 중앙대학교 사진학과 졸업
2018 M.A. in course Fine Art Photography, Chung-Ang University, Korea
2013 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Group Exhibitions </strong>
2017 ‘Community Art, Annyeonghaseyo’, Buk Seoul Museum of Art, Seoul
‘Chiang Mai Photo Festival 2017 : YOUNG EYES+’, Chiang Mai University, Chiang Mai, Thailand
2016 ‘Jirisan Project 2016 - Porject SIZAK’, Silsangsa, Namwon
‘New Generation - SIZAK’, Laznia Center for Contemporary Art, Gdansk, Poland
‘PHOTOBOOK LOUNGE PROJECT 04: Memory’, Hanmi The Museum of Photography, Seoul, Korea
‘.jpg’, space-nowhere, Seoul, Korea
2013 ‘The 2nd International Photo Exhibition of Asian University Students’, Seoul, Korea
‘2013 The Scales of Dragon - Exhibition of Young Artists’, Ansung, Korea
</p>
`, website: ' '
}, 

// 정지현
'JihyunJung': {
    content: `
<p>1983년 서울출생, 서울에서 거주 및 활동
Born in 1983 Seoul, Korea. Lives and works in Seoul

<strong>Education</strong>
2019 중앙대학교 일반대학원 예술학과 박사 수료
2016 중앙대학교 일반대학원 사진학과 석사
2010 중앙대학교 예술대학 사진학과 학사
2019 PH.D. Candidate Chung-ang University, Department of Art Science, Seoul, Korea
2016 M.F.A Chung-ang University, Department of photography, Seoul, Korea
2010 B.F.A Chung-ang University, Department of photography, Seoul, Korea

<strong>Solo Exhibitions </strong>
2023 Zooming In, GOP, Seoul, Korea
2022 Deconstruct, Gallery Purple, Namyangju, Korea
2021 Reconstruct, SongEun Art Space, Seoul, Korea
2021 Re-construct, HPIX DOSAN, Seoul, Korea
2018 CONSTRUCT, KT&G Sangsangmadang, Seoul, Korea
2016 Reconstruction Site, SPACEONEWWALL, Seoul, Korea
2015 SITE, Goeun Museum Of Photography BMW Photo Space,, Busan, Korea
2014 Oppo-Site, Total Museum Project space The room, Seoul, Korea
2014 Demolition Site, KT&G Sangsangmadang, Seoul, Korea
2013 Construction Site, SongEun ArtCube, Seoul, Korea

<strong>Selected Group Exhibitions </strong>
2024 Jung Youngsu: For All That Breathes On Earth, MMCA, Seoul, Korea
2023 Birth: Reverse, Seoul National University powerplant, Seoul, Korea
Spaceless, The 14th Gwangju Biennale Swiss Pavilion, Gwangju
Insights into Nature and Humanity, The Korean Cultural Center in Roma, Italy
Insights into Nature and Humanity, The Korean Cultural Center in Budapest, Hungary
Spaceless+60 Swiss Photobooks, Artspace LUMOS, Daegu, Korea
LBDF x XXPRESS, XXPRESS, Seoul, Korea
Archive, Space55, Seoul, Korea
2022 The 22nd SONGEUN Art Award Exhibition, SONGEUN, Seoul, Korea
Spaceless, Embassy of Switzerland in the Republic of Korea, Seoul, Korea
Beyond the limits, Daegu Art Factory, Daegu, Korea
G.P.S 5th Art Navigator, Gallery Purple, Namyangju, Korea
2021 Herzog & de Meuron. Exploring SongEun Art Space, SongEun, Seoul, Korea
KT&G SKOPF: PIC, KT&G Sangsangmadang, Busan, Korea
KT&G SKOPF: PIC, KT&G Sangsangmadang, Seoul, Korea
Talent Portfolio 2021 group exhibition, The Museum of photography Seoul MoPS, Seoul, Korea
Hanseong Baekje Culture Festival, Seokchon Lake ArtCube, Seoul, Korea
Why I Take Photographs,Project Room Sinpo,  Incheon, Korea
Aarahee, Eum the Place, Seoul, Korea
Wonderful days for all, Dongdaemun History & Culture Park (DDP) Station, Seoul, Korea
2020 Love In The Time of Corona, Place mak3, Seoul, Korea
2019 Homagination, Space22 Ikseon, Seoul, Korea
SeMA Collection: Apartment, Yeoksam 1 Culture Center, Seoul, Korea
3^x=∞, Incheon Art Platform, Incheon, Korea
The Memory of Space, Kimchungup Architecture Museum, Anyang, Korea
2018 Heaven Earth & Man, Seoul Museum of Art, Seoul, Korea
The Show Must Go On, Korean Culture Center, Warsaw, Poland
Spacing Being Timing, Amorepacific Museum of Art, Seoul, Korea
Nonunifiable Heterogeneous Individuals, Gyeonggi Creation Center, Ansan, Korea
Festival GCC, Gyeonggi Creation Center, Ansan, Korea
Unofficial Discourser, Gyeonggi Creation Center, Ansan, Korea
Way to Close Transparency and to Open Darkness, Urban Concrete, Seoul, Korea
Uiartline: Bukhansan Reflection, Bukhansan station, Seoul, Korea
2017 BOUNDARY, KT&G Sangsangmadang-Daechi Art Hall, Seoul, Korea
2016 Seoul New 新 Arirang, Buk Seoul Museum of Art, Seoul, Korea
Encounter IV, The 6st Daegu International Photo Biennale, Daegu, Korea
Home-ground, Cheongju Museum of Art, Cheongju, Korea
Kairos in Photographs, KT&G Sangsangmadang, Chuncheon, Korea
2015 Scotiabank CONTACT Photography Festival, Toronto, Canada
Intro, National Museum of Modern and Contemporary Art, Seoul, Korea
Ex-AIR, MMCA Residency Goyang, Goyang, Korea
Open-Studio 11: De-position, MMCA Residency Goyang, Goyang, Korea
TWS Residency Open Studio 2015-2016, Tokyo Wonder Site Residence, Tokyo, Japan
Ruins, Korean cultural center shanghai, Shanghai, China
System planning: Dabang Project, KT&G Sangsangmadang, Seoul, Korea
Namdo Art Project, Imhado Studio, Haenam, Korea
Empty House: Project, Yongil Market, Incheon, Korea
Summer Love, SongEun Art Space, Seoul, Korea
Place, People and Relations: Chuncheon Archives Project, KT&G Sangsangmadang,
Chuncheon, Korea
2014 Site & Place (Duet exhibition), Doosan Gallery, New York, US
The 36th JoongAng Fine Arts Prize, Seoul Arts Center-Hangaram Art Museum, Seoul, Korea
The 6th KT&G SKOPF New Force of Photography, Goeun Museum Of Photography, Busan,
Korea
New Generation, Gallery Image, Aarhus, Denmark
New Generation, The Museum of photography Seoul, Seoul, Korea
The First Encounter, The Beginning of ‘Empty’ Friendship, Incheon Art Platform, Incheon,
Korea
Final Report, Incheon Art Platform, Incheon, Korea
Change in incheon City, Tribowl, Incheon, Korea
Sajin Bipyong, Space 22, Seoul, Korea
2013 LianZhou International Photo Festival, GuangDong, China
Incheon Art Club, Incheon Art Platform, Incheon, Korea
The Show Must Go On in Singapore, Praxis Space, ICAS, Singapore
Road Show, Total Museum, Seoul, Korea
Baengnyeongdo 525,600 minutes: Interview, Incheon Art Platform, Incheon, Korea
Residency Now, Songwon Art Center, Seoul, Korea
Platform Access: Incheon Art Platform, Incheon, Korea
The 14th SAJINBIPYONG Award Exhibition, Gallery Iang, Seoul, Korea
2012 Gongteo project, Cheongju, Korea
In Transit, European Month of Photography Berlin, Berlin, Germany
Speciaal project fotografisch talent uit Korea Breda international photo festival, Breda,
Netherland
Art Factory project: Sunset Janghang festival, Jang hang, Korea
Asian Students and Young Artists Art Festival 2012, Culture Station Seoul 284, Korea
2010 Hidden sense,The 1st Daegu Photo Biennale, Daegu, Korea
Emerging Artists, Doosan Gallery, Seoul, Korea

<strong>Residency Programs </strong>
2021 Gallery Purple Studio, Gallery Purple, Namyangju
2020 SongEun Artists Studio, Seoul, Korea
2018 Gyeonggi Creation Center, Ansan, Korea
2015 Goyang Art Studio, National Museum of Modern and Contemporary Art, Goyang, Korea
           Tokyo Wonder Site Creator in Residence, Tokyo, Japan
2014 Incheon Art Platform 5th Artist Residency, Incheon, Korea
2013 Incheon Art Platform 4th Artist Residency, Incheon, Korea

<strong>Awards, Projects</strong>
2023 Head On Photo Awards 2023: Landscape Finalist, Sydney, Australia
2021 MoPS Talent Portfolio: award recipient, The Museum of photography Seoul, Seoul, Korea
           Leica Oskar Barnack Award 2021: Nominated, Wetzlar, Germany
2018 Seoul Foundation for Arts and Culture Grant, Seoul, Korea
           SongEun Artstorage: Artist Commission, SongEun Art Space, Seoul, Korea
2017 Amorepacific Museum of Art (APMA) Art Commission, Seoul, Korea 
2016 Prix Pictet Nomination, Geneva, Switzerland
           Seoul Foundation for Arts and Culture Grant, Seoul, Korea
           Hyupsung Foundation for Arts and Culture Grant, Busan, Korea
2014 The 5th Daegu International Photo Biennale-The top four 2014 portfolio award recipient,
Daegu, korea
           The 36th Joongang Fine Arts Prize Selected Artists, JoongAng llbo, Korea    
2013 The 6th KT&G Sangsangmadang  Korean Photographer’s Fellowship- Final artist of the Year, KT&G Sangsangmadang, Seoul, Korea
           The 14th SAJINBIPYONG Award, photo space, Seoul, Korea
           Arko Artist Program, Arko Art Center, Seoul, Korea

<strong>Public Collections</strong>
National Museum of Modern and Contemporary Art, Seoul, Korea
Seoul Museum of Art, Seoul, Korea
Seoul Metropolitan Government
Busan Museum of Contemporary Art, Busan, Korea
KT&G Sangsangmadang, Seoul, Korea
The Museum of photography Seoul, Seoul, Korea
GoEun Museum of Photography, Busan, Korea
AmorePacific Museum of Art, Seoul, Korea
SongEun Art Space, Seoul, Korea
SPACEONEWWALL, Seoul, Korea
Workroom press, Seoul, Korea
HPIX, Seoul, Korea
Gallery Purple, Namyangju, Korea

</p>
`,website: ' '
}, 

// 김태중
'TaejoongKim': {
    content: `
<p>1986년 대전출생, 서울에서 거주 및 활동  
Born in 1986 Daejeon, Korea. Lives and works in Seoul

<strong>Education </strong>
2015 중앙대학교 일반대학원 순수사진전공 졸업
2013 중앙대학교 사진학과 졸업
2015 M.F.A. in Fine Art Photography, Chung-Ang University, Korea
2013 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2014 Sequence, Laznia - Center for Contemporary Art, Gdansk, Poland

<strong>Group Exhibitions </strong>
2016 Artists from Daejeon at the hill on the Seine, Daejeon City Hall, Daejeon, Korea
2015 'Nakwon Yeop, Daelim Sanga, Seoul, Korea
           MOUVEM3NTS, Goam Académie, Vaux-sur-Seine France
           Longing Objects, Kunsthalle Göppingen, Göppingen, Germany
           PhotoIreland Festival – JIGEUM, South Studio, Dublin, Ireland
2014 'Photobook Week Aarhus, Galleri Image, Aarhus, Denmark
           New Generation - SIZAK, The Museum of Photography, Seoul, Korea
           New Generation - SIZAK, Galleri Image, Aarhus, Denmark
2013 'Trialog, Total Museum of Contemporary Art, Seoul, Korea
           Trialog, Goethe-Institut, Seoul, Korea
           PHOTOVISA, CLIMATE of CHANGES, Krasnodar, Russia
           Lianzhou International Photo Festival, Lianzhou, China
           Alt. +1000 Festival de Photographie, Rossiniere, Switzerland
           Works Without Works, Space Haam, Seoul, Korea
2012 '5th European Month of Photography Berlin, IN TRANSIT, Berlin,Germany
           The Art Factory Project, Janghang, Korea
           Breda Photo-International Festival, MOTI, Museum of the Image, Breda, Netherlands

<strong>Public Art </strong>
2015 '3X2X1', Daelim Sanga, Seoul, Korea

<strong>Artist in Residency </strong>
2015 Kunsthalle Göppingen, Göppingen, Germany
           Paris Lee Ungno Residence, Vaux-sur-Seine, France
2014 Laznia- Center for Contemporary Art, Gdansk, Poland

<strong>Awards and Grants </strong>
2016 Goethe-Institut, Seoul, Korea
2015 Goam Art Foundation, Vaux-sur-Seine, France
           Honorary Mentions, Photo Ireland, Dublin, Ireland
2014 Samsung Fashion KUHO Art Collaboration, Seoul, Korea
2012 UNUS Art Project, Seoul, Korea
</p>
`,website: ' '
}, 

// 최은주
'EunjuChoi':  {
    content: `
<p>1993년 서울출생, 서울과 뉴욕에서 거주 및 활동 
Born in 1993 Seoul, Korea. Lives and works in Seoul and NewYork

<strong>Education </strong>
2021 중앙대학교 일반대학원 순수사진 전공 석사 졸업
2016 School of Visual Arts, Photography & Video 졸업
2021 M.A. in course Fine Art Photography, Chung-Ang University, Korea
2016 B.F.A in Photography & Video, School of Visual Arts, USA

<strong>Solo Exhibitions </strong>
2019 New-Family Portrait, 64 Gallery, Ansung, Korea
2017 Fa(r)ther, 64 Gallery, Ansung, Korea
2015 최은주, SVA Photo Gallery, New York, USA

<strong>Group Exhibitions </strong>
2019 Homagination: An Homage to 180-Year Photography, Space 22 Ikseon, Seoul, Korea
2017 EXPANDERS, My Fathers Garage, Seoul, Korea
         4 element of ART, Jeonju International Photo Festival Young Artist Exhibition, Jeonju, Korea
2016 Tracing, K&P Gallery, New York, USA
         Senior Thesis Show, SVA BFA Photography Gallery, New York, USA
         Mentor Show, SVA Chelsea Gallery, New York, USA
</p>
`, website: ' '
}, 

// 정찬민
'ChanminJung':  {
    content: `
<p>1991년 11월 20일 출생, 서울에서 거주 및 활동 
Born in 1991 Korea. Lives and works in Seoul

<storng>Education </strong>
2020 중앙대학교 일반대학원 순수사진전공 졸업 
2015 중앙대학교 사진학과 졸업
2020 M.F.A Chung-Ang University, Fine Art Photography, Korea
2015 B.F.A Fine ART Photography, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2019 A Pensive Image, Anjeong-art Space, Seoul, Korea
2017 Shaping Spaces , Space One, Seoul, Korea

<strong>Group Exhibitions </strong>
2021 n-Sensitivity, Space TYPE, Seoul, Korea
2020 사사이람, Seoul-Citizens Gallery, Seoul, Korea
틈을 비집고 들어가기, Barim, Gwangju, Korea
2019  0 to Square, Shang-Up Gallery, Seoul, Korea
Full Metal Jacket_Gangwon International Biennale, Gangwon-do, Korea
The Expanding Flatness, KT&G SangsangMadang Art Gallery, Chuncheon, Korea
2018  ‘GLANCING INTO THE SUN_pink factory 2018 international exhibitionʼ,Hongcheon Art Museum, Gangwon-do, Korea
‘Single Room worldʼ, 175 Gallery, Korea
2017  ‘Moving Shadow_pink factory 2017 international exhibitionʼ, Hongcheon Art Museum, Gangwon-do, Korea
</p>
`,  website: ' '
}, 


// 정영호
'YounghoJung': {
    content: `
<p>1989년 서울출생, 서울에서 거주 및 활동
Born in 1989 Seoul, Korea. Lives and works in Seoul

<strong>Education </strong>
2018  왕립예술대학 사진 석사 졸업, 런던, 영국
2018  M.A in Photography, Royal College of Art, London, UK  2014  중앙대학교 사진학과 졸업
2014  B.F.A in Fine Art Photography Chung-Ang University, Korea 

<strong>Solo Exhibitions </strong>
2021  Out of Photography, SongEun Art Cube, Seoul, Korea

<strong>Selected Group Exhibitions </strong>
2018  Show 2018 - Royal College of Art, London, UK
Night Visions - Museum of London, London, UK
SightUnseen - Dyson Gallery, London, UK
2017  Unexpected - Hoxton Arches Gallery, London, UK
KhojalyPeace Prize - Houses of Parliament (Palace of Westminster), London, UK

<strong>Awards & Artist in Residency </strong>
2021  서울문화재단 예술기반지원, 서울
서울문화재단 예술창작활동지원, 서울
</p>
`, website: ' '
}, 

// 정승원
'SeungwonJung': {
    content: `
<p>1992년 서울출생, 런던에서 거주 및 활동
Born in 1992 Seoul, Korea. Works and lives in London

<strong>Education </strong>
2019 런던 대학교 슬레이드 예술 대학, 순수 미디어 석사
2017 중앙 대학교 예술 대학, 순수 사진 학사
2015 바우하우스 바이마르 대학교, 미디어 아트&디자인
2019 MFA Fine Art Media, Slade School of Fine Art, University College London, United Kingdom
2017 BFA Fine Art Photography, Chung-Ang University, South Korea 
2015 Exchange Programme, Media Art and Design, Bauhaus Universität Weimar, Germany

<strong>Selected Exhibitions </strong>
2021 Hot Sheet, Downstairs, London, United Kingdom
MoPS Talent Portfolio, Museum of Photography, Seoul, South Korea
2020 Pending, San Mei Gallery, London, United Kingdom
public notice : an exhibition, London, United Kingdom
FBA Futures, Mall Galleries, London, United Kingdom
Digital Strata, The London Arts Board, United Kingdom
2019 Exceptional, Collyer Bristow Gallery, London, United Kingdom
Digital Strata, The London Arts Board, United Kingdom
TPG New Talent, The Photographers’ Gallery, London, United Kingdom
Wells Art Contemporary 2019, Wells Cathedral, Somerset, United Kingdom
Slade Show 2019, Slade School of Fine Art, London, United Kingdom
Fast Forward : Women in Photography, Slade Research Centre, London, United Kingdom
Secrets on the Way, Sluice HQ, London, United Kingdom
Bite the Bullet, Barbican Arts Group Trust - ArtWorks Project Space, London, United Kingdom
2018 The Salamander Devours its Tail Twice, GALLERY 46, London, United Kingdom
Redress, UCL Art Museum, London, United Kingdom
Copernicus & Galileo, Pinch Project Space, London, United Kingdom
SWAP EDITIONS 3, Red Gallery, London, United Kingdom
2017 The Salamander Devours its Tail Twice, GALLERY 46, London, United Kingdom
RFAN : A Research on Feminist Art Now, No New Work & Art Space One, Seoul, South Korea
2016 Suwon PASA Festival, Art Space Bom, Suwon, South Korea
VISION 2016, Topohaus Gallery, Seoul, South Korea
Loft - Yard, Art Space One, Seoul, South Korea
2016 Suwon PASA Festival, Art Space Bom, Suwon, South Korea
TWISTED MATTERS, Amalienstrasse 13, Weimar, Germany

<strong>Awards & Artist in Residency</strong>
2021 MoPS Talent Portfolio 2021, Museum of Photography, Seoul, South Korea
2019 Exceptional 2019, The Collyer Bristow Graduate Art Award, Collyer Bristow Gallery, United Kingdom
           Cass Art Prize, The WAC Awards 2019, Wells Art Contemporary, United Kingdom
           TPG New Talent Award, The Photographers’ Gallery, United Kingdom
2018 UCL Art Museum, London, United Kingdom

<strong>Publication </strong>
2021 WEAVE IT!, Decorating Dissidence, United Kingdom
           CAMERA WORK 35 MoPS Talent Portfolio : Seungwon Jung ∙ Jihyun Jung, Museum of Photography, Seoul, South Korea
2020 HOT SHEET(S), Hotsheet , United Kingdom
           Board 1-10, The London Arts Board, United Kingdom
2019 Saatchi Art Rising Stars Report 2019, Saatchi Art, United States
           Beyond #5 Time, Farside Collective, India
2018 A Research on Feminist Art Now: Re-record, No New Work, South Korea
2016 Jigeum Vol.4, Sizak, South Korea

<strong>Press </strong>
2021 (Aug) Texture of Time, Texture of Space, Photo Art Magazine, South Korea
2020 (Jan) Culture highlights of the week, The Guardian, United Kingdom
2019 (Dec) Women Crush Wednesday : Seungwon Jung, Musée Magazine, United States
           (Oct) Elastic Forms, Elastic Visions, LensCulture Magazine, United Kingdom
           (Oct) The Photographer’s - Seungwon Jung, Monthly Photography Magazine, South Korea
           (Jul) Artist Seungwon Jung, X = Y Magazine, Netherlands
           (Jun) Meet eight of tomorrow’s most exciting young photography talents, HERO Magazine, United Kingdom
2018 (Nov) Salamanders and the Vicious Circle of Sociocultural Pressure, FAD Magazine, United Kingdom
</p>
`, website: 'www.jungseungwon.com'
}, 

// 크리스티안
'CristianDoeller': {
    content: `
<p>Born 1987 in Darmstadt (Germany), lives and works in Leipzig

<strong>Education </strong>
2018 M.F.A. Media Arts, Bauhaus-University, Weimar
2015 B.A. Photography and Media, University of Applied Sciences, Bielefeld
2012 Photography and Sculpture, Chung-Ang University, Seoul
2009 physics studies, Technical University, Darmstadt

<strong>exhibitions (selection) </strong>
2022 'Speculative Sensing I - Light Sensor‘‚ IDEAL Art Space, Leipzig
           'Where Are We Now?‘, Sungkok Art Museum, Seoul, South Korea
2021 'CYTTER‘, Matjö Raum für Kunst, Cologne (solo)
           Und/Oder‚ Galerie in der Lände, Kressbronn
           'Neue Aussichten‘, Goethe-Institut Seoul, South Korea
2020 'Parallel Worlds‘, Kunstmuseum Celle, Celle
           'Blue Quarantine‘, Monopol, Leipzig
           'CYTTER.datalab‘, LEW1, Darmstadt
2019 'Transcription‘, Vašulka Kitchen Brno, Brno, Czech Republic (solo)
           'From the Lab to the Studio‘, Galerie Eigenheim, Berlin
           'HUM. Generative Experiments with Form and Sound‘, Studio Wägetechnik, Weimar
2018 'Replay‘, Neue Galerie, Kassel (solo)
           'Verborgenes Licht‘, Museum für Fotografie, Berlin
           'Virtualities‘, Bauhaus-University, Weimar
2017 'Shared Habitats‘, NKNU Art Center, Kaohsiung, Taiwan
           'Human Factor‘, Warte für Kunst, Kassel (solo)
2016 'Darmstädter Tage der Fotografie‘, Darmstadt
           'Agar! Agar!‘, Schillerpalais, Berlin
2015 'Lichtbild und Datenbild‘, Museum im Kulturspeicher, Würzburg
           'Large Half Marathon Circuit‘, Performance, CERN, Switzerland/France
           'Konkrete Kunst‘, Kunstverein Bad Nauheim

<strong>teaching</strong>
since 2022  lecturer, Faculty of Arts and Design, Bauhaus-University Weimar
2021-2022  teaching assignment, Faculty of Arts and Design, Bauhaus-University Weimar
           Grants / Awards / Residencies

<strong>Award&Residency</strong>
2022 scholarship, Stiftung Kunstfonds
2021 project funding, BBK Bundesverband
2020 exhibition funding, Stiftung Kulturwerk, VG Bild-Kunst
           project funding, Kulturstiftung des Freistaates Sachsen
           Artist in Residence, Kultur einer Digitalstadt e.V., Darmstadt
2019 shortlisted for Bauhaus Media Art Award
           Artist in Residence, Vašulka Kitchen, Brno, Czech Republic
           Plat(t)form 2019, portfolio walk, Fotomuseum Winterthur, Switzerland
2017 Artist in Residence, Allenheads Contemporary Arts, Allenheads, UK
           Artist in Residence (ItsNotLikeThis – collective), offspace, Erfurt
2012 DAAD grant for studying aborad

<strong>Publications</strong>
2021 CYTTER.log, artist book, self publishing
           in: Ursula Damm, Mindaugas Gapsevicius (eds.), Shared Habitats. A Cultural Inquiry into Living Spaces and Their Inhabitants, Transcript Verlag
2020 in Photo Researcher No. 33, European Society for the History of Photography, Franziska Kunze, The Digital Body: An Instrospection of the Mediation of Expanded Photographic Practices
2019 in Franziska Kunze, Opake Fotografien: Das Sichtbarmachen fotografischer Materialität als künstlerische Strategie, Reimer Verlag
           in Suse Wiegand, SIEBEN, Über das Ein- und Zurichten von Dingen im Raum, artist book, self publishing
2018 Ontology Generator, artist book, self publishing
           in Monthly Photography, Photography Magazine, South Korea
2016 in HANT – Magazin für Fotografie, Ausgabe 08
2015 in Henrike Holsing, Gottfried Jäger, Bernd Stiegler, Lichtbild und Datenbild: Spuren Konkreter Fotografie, KEHRER Verlag
           Habitable Zone, artist book, self publishing

<strong>Talks / Presentations </strong>
2021 Epistemologien der Sensorsysteme, Universität Regensburg
           Artist Talk, University of Applied Sciences Bielefeld
           Artist Talk, Open Lab, KHM – Academy of Media Arts Cologne
           Artist Talk, Burg Giebichenstein Kunsthochschule Halle
           12th International Conference on Artistic Research, Society for Artistic Research
           Artist Talk, Chung-Ang University, Sculpture Department, Seoul, Southkorea
2020 Basisdemokratisch Kuratieren?, with KV – Verein für Zeitgenössische Kunst Leipzig, Universität Hildesheim
           Artist Talk, Authentic Spaces, Artist-in-Residence program by This is Fake - collective, Zeitz
2019 Artist Talk, Faculty of Fine Arts (FAVU), Performance and Media, Technical University Brno, Czech Republic
           Artist Talk, Brno Art Week, The House of the Lords of Kunstát, Brno, Czech Republic
</p>
`,  website: 'www.christiandoeller.de'
}, 



// 이예은
'YeeunLee': {
    content: `
<p>1994년 서울출생, 이천에서 거주 및 활동
Born 1994 in Seoul, Korea, lives and works in icheon, Kore

<strong>Education </strong>
2021 중앙대학교 일반대학원 순수사진전공 수료 
2019 상명대학교 사진영상미디어학과 졸업
2021 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2019 B.F.A Photography, Sang-myung University, Korea

<strong>Solo Exhibitions </strong>
2021 227 People, Goeun Museum Of Photography BMW Photo Space, Busan, Korea

<strong>Group Exhibitions </strong>
2022 TOTAL SUPPORT, Total Museum, Seoul, Korea
           Dot to Dot, Gallery Purple, Namyangju, Korea
           META-gram 2022, Space22, Seoul, Korea
2021 Actually the dead are not dead, Total Museum, Seoul&Anseong, Korea
           Breathing Walls, Swiss Embassy, Seoul, Korea
           Respect plant, SeMa Storage, Seoul, Korea
           Visvble Voices, Laznia-Center for Contemporary Art, Gdansk, Poland
           Encounter VI, The 8st Daegu International Photo Biennale, Daegu, Korea
           Family Photography and Goodbye Photo Album, Art Spave Unju Round, Seoul, Korea
2019 Homagination, Space 22Ikseon, Seoul, Korea
2018 Kualalumpur International photo festival, Kuala Lumpur, Malaysia
           17th DongGang International Photo Festival Street Installation Exhibition <Yeongwol Sunshine>, DongGang Museum of Photography, Yeongwol, Korea

<strong>Awards</strong>
2018 2018 The 7th Daegu International Photo Biennale-The top four 2018 portfolio award recipient, Daegu, korea

<strong>Public Collections </strong>
2021 Goeun Museum Of Photography
</p>
`, website: ''
}, 


// 김도영
'DoyoungKim': {
    content: `
<p>1992년 광주 출생, 서울에서 거주 및 활동
Born in 1992 Gwangju, Korea, Lives and Works in Seoul.

<strong>Education </strong>
2019 중앙대학교 일반대학원 순수사진전공 수료 
2016 중앙대학교 사진학과 졸업
2019 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2016 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2021 Thin/ Thick, DDF, Gwangju, Korea

<strong>Group Exhibitions </strong>
2022 ‘Spaceless’, Embassy of Switzerland in the Republic of Korea, Seoul, Korea
‘Good Bye Photography’, The Reference, Seoul, Korea
‘Where are We Now?’, Sungkok Museum, Seoul, Korea
2021 ‘Breathing Walls’ Embassy of Switzerland in the Republic of Korea, Seoul, Korea
‘The Waiting Time’, CCP Wall Gallery, Seoul, Korea
‘Young Korean Photography Now!’, Laznia contemporary Art Museum, Gdansk, Poland
2020 ‘Calling’, d/p, Seoul, Korea
</p>
`, website: ''
}, 

// 이현우
'HyeonwooLee': {
    content: `
<p>1990년 인천 출생, 인천과 서울을 오가며 활동 
Born in 1990 Incheon, Korea. Lives in Incheon and works in Incheon&Seoul

<strong>Education </strong>
2021 중앙대학교 일반대학원 순수사진전공 수료
2019 중앙대학교 사진학과 졸업
2021 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2019 B.F.A in Fine Art Photography , College of Arts, Chung-Ang University, Korea

<strong>Solo Exhibitions </strong>
2021 Floating island, Projectroom Sinpo, Incheon

<strong>Group Exhibitions </strong>
2022 Where Are We Now?, Sungkok Art Museum, Seoul, Korea
2021 Breathing walls, Embassy of Switzerland, Seoi Gallery, Seoul, Korea
Visible Voices, Laznia Center for Contemporary Art, Gdansk, Poland
2015 ASYAAF Media part, Chosunilbo, Seoul, Korea

<strong>Awards</strong>
2021 인천문화재단 예술표현활동지원, 인천

</p>
`, website: 'www.hyeonew.kr'
}, 

// 11.24 추가
// 조이폽 Zoe Popp
'ZoePopp': {
    content: `
<p>Born in 1999 Berlin, Germany. Lives and works in Berlin and Leipzig.

<strong>Education </strong>
2024 Studies Architecture at University of the Arts Berlin
2022 Prediploma
2020 Diploma studies in Fine Arts, Academy of Visual Arts Leipzig,

<strong>Group Exhibitions </strong>
2024 „Kammerspiel“, HEIT, Berlin Germany
2023 „Topologies“, Galerie Ghosh, Leipzig, Germany
2023„EXPECT THE UNEXPECTED – STUDENTS REELS“, at the Kunstmuseum Bonn, Germany
2022 „Field“, group exhibition in Jena, Germany
2022 „293,94 km“, FRISE Künstlerhaus Hamburg e. V., Germany
</p>
`, website: ''
}, 


// 장소영
'SoyoungJang': {
    content: `
<p>1996년 충주 출생, 서울에서 거주 및 활동
Born in 1996 Chungju, Korea. Lives and works in Seoul

<strong>Education </strong>
2022 중앙대학교 일반대학원 조형예술학과 순수사진전공 수료
2021 중앙대학교 공연영상창작학부 사진 전공 졸업
2022 M.A. course in Fine Art Photography, Chung-Ang University, Korea
2021 B.F.A in Fine Art Photography , Colege of Arts, Chung-Ang University, Korea

<strong>Group Exhibitions </strong>
2024 The 21st Photography Criticis Awards, Chungmuro Galery, Seoul, Korea
2023 We, Shining Values, Gyeonggi Sangsang Campus Space 1986, Suwon, Korea
2023 Photographs, Familiar and Unfamiliar Words, Ami Art Museum, Dangjin, Korea
2023 Ready to Shoot, SAPY White Room, Seoul, Korea

<strong>Awards</strong>
2024 The 21st Photography Criticis Awards, Photography Critique Committee, Seoul, Korea
2024 Climate Change Photo Project Excelence Award, Junggu Cultural Foundation, Seoul, Korea

</p>
`, website: ''
}, 


// 유혜정
'HyejeongYoo': {
    content: `
<p>1992 서울 출생, 독일 라이프치히에서 거주 및 활동
Born in 1992 Seoul, Korea, lives and works in Leipzig, Germany

<strong>Education </strong>
2024 라이프치히 미대, 마이스터 (Heidi Specker 교수 지도) 졸업
2022 라이프치히 미대, 디플로마 (Heidi Specker 교수 지도) 졸업
2016 중앙대학교 공연영상창작학부 사진학과 졸업
2013 바우하우스 대학교, 바이마르, 교환학생

2024 Meisterschülerin with Prof.Heidi Specker, Academy of Fine Arts Leipzig (HGB), Germany
2022 Diploma in photography (with honours), Class of Prof.Heidi Specker, HGB Leipzig, Germany
2016 B.F.A in Photography, Chung-Ang University, Seoul, South Korea
2013 Student exchange program in Media Art, Bauhaus University, Weimar, Germany


<strong>Solo Exhibitions </strong>
2024 We Will Hurt Each Other, Bibliotheca Albertina, Leipzig, Germany
2022 Carrying, Caring, HGB Gallery, Leipzig, Germany (Diploma show)

<strong>Group Exhibitions </strong>
2024 Styrian Photo Biennale, Graz, Austria
           Analysis of Anger, Werkschauhalle Spinnerei, Leipzig, Germany
           Spaces: Leipziger Kunsträume im Fokus, Werkschauhalle Spinnerei, Leipzig, Germany
           Rally, Bistro 21, Leipzig, Germany
           I can't stop Andy from growing up, Alternative Archive, Seoul, South Korea
           Aesthetica Art Prize (Longlist Showcase), York Art Gallery, England
2023 gute aussichten, New German Photography 2022/2023, Kunsthalle Pfaffenhofen, Germany
           gute aussichten, New German Photography 2022/2023, Deichtorhallen Hamburg, Germany
           gute aussichten, New German Photography 2022/2023, Landesmuseum Koblenz, Germany
           SIZAK x Ami Art Museum, Dangjin, South Korea
           the continuous stream of beginnings and endings, alpha nova & galerie futura, Berlin, Germany
           PEP New Talents 2022, 254 Forest, Brussels, Belgium
           2022 Treues Herz, Goethe-Institut Seoul, South Korea
           Fotohof Calling, Fotohof Studio, Salzburg, Austria
           Angenommen, Hier, Neue Galerie für Zeitgenössische Kunst, Gera, Germany
           A5, AS/EM, curated by Erik Swars, Leipzig, Germany
2021 Margo, Me and More, HGB Gallery, Leipzig, Germany
           Fernweh, Poiesis Galerie, Markkleeberg, Germany
           Come Closer, Spinnerei Halle 14 UG, f/stop Reflections, Leipzig, Germany
2020 The World is Not Enough, curated by Hanna Stiegler and Paul Niedermayer, Adalberstr.9, Berlin, Germany
2019 Science-Fiction-History, Gallery of European Art School of Bretagne, Quimper, France
           Let Us Stop Here, Gallery SMAC, Berlin, Germany
           World Biennial Of Student Photography, Novi Sad, Serbia
2018 Copenhagen Photo Festival, Denmark
           Photokina, Cologne, Germany
2017 After You, Kunstraum D21, Leipzig, Germany
2015 Vision, Gallery Luminarie, Seoul, South Korea
2014 5色, Yoon Design Gallery, Seoul, South Korea

<strong>AWARDS / GRANTS</strong>
2023 Rössing-Price for Photography, Leipzig, Germany
           gute aussichten, New German Photography 2022/2023, Germany
2022 Grant / Artist-in-Residence, Camera Austria & Kulturvermittlung Steiermark, Graz, Austria
2020 Study Award of the HGB Friends and Sparkasse Leipzig, Germany
           Artist-in-Residence, SASSO Residency, Vairano, Switzerland
2018 German Youth Photo Award (Deutscher Jugendfotopreis), Cologne, Germany
</p>
`, website: 'https://hyejeongyoo.de'
}, 

};
